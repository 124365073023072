@import "shared/styles/core";

$switch-width: 48px;
$switch-height: 28px;
$tick-width: 18px;
$tick-height: 24px;
$dash-width: 7px;

.label {
  @include border-with-shadow;

  cursor: pointer;
  text-indent: -9999px;
  width: $switch-width;
  height: $switch-height;
  background: var(--surface-tertiary);
  display: block;
  position: relative;

  &::after {
    content: "|";
    position: absolute;
    top: 0;
    left: 0;
    width: $tick-width;
    height: $tick-height;
    background: var(--core-black);
    transition: 0.2s linear;
    border-right: $default-border-width solid var(--core-black);
    border-left: none;
    box-shadow: inset 0 0 0 $dash-width var(--core-white);
  }

  &:active::after {
    width: $tick-width;
  }
}

.input {
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + label {
    background: var(--primary-p500);
  }

  &:checked + label::after {
    border-left: $default-border-width solid var(--core-black);
    border-right: none;
    left: 100%;
    transform: translateX(-100%);
    background: var(--primary-p500);
  }
}
