@import "shared/styles/core";

.container {
  padding-left: ($spacing * 4);
  padding-right: ($spacing * 4);
}

@include media-breakpoint-up(lg) {
  .container {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(xl) {
  .two-col,
  .three-col,
  .twelve-col {
    display: grid;
    grid-gap: $default-grid-gap;
  }

  .two-col {
    grid-template-columns: repeat(2, 1fr);
  }

  .three-col {
    grid-template-columns: repeat(3, 1fr);
  }

  .twelve-col {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
