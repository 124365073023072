@import "shared/styles/core";

.wrapper {
  margin-top: $fixed-menu-spacing-mobile;
  transition: transform 0.4s ease-in;
  //-webkit-transform-style: preserve-3d;
  backface-visibility: hidden;

  &.pwa {
    margin-top: $fixed-menu-spacing-pwa;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    margin-top: $fixed-menu-spacing-desktop;
    padding: 0;
  }
}

@media all and (display-mode: standalone) {
  .wrapper {
    margin-top: $fixed-menu-spacing-pwa;
  }
}
