@import "shared/styles/core";

$tooltip-mobile-position: 48px;
$triangle-position-top: -9px;
$triangle-position-left: 25px;
$triangle-size: 15px;

.container {
  @include border-with-shadow;

  position: absolute;
  top: $tooltip-mobile-position + $logobar-height;
  margin-left: $spacing * 25;
  background-color: var(--surface-secondary);
  max-width: $spacing * 55;
  padding: $spacing * 3;
  padding-top: $spacing * 4;
  z-index: $layer-1;
  cursor: default;

  &::before {
    content: "";
    top: $triangle-position-top;
    width: $triangle-size;
    height: $triangle-size;
    border-left: $default-border-width solid black;
    border-bottom: $default-border-width solid black;
    position: absolute;
    left: $triangle-position-left;
    transform: rotate(135deg);
    background-color: var(--surface-secondary);
  }

  &.mobile {
    top: $tooltip-mobile-position;
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
  width: ($spacing * 6);
  height: ($spacing * 6);
  cursor: pointer;
  position: absolute;
  top: $spacing * 2;
  right: $spacing * 2;

  .closeIcon {
    color: var(--content-primary);
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.text {
  margin: ($spacing * 2) 0;
  font-family: var(--font-family-body);
  font-size: $spacing * 3;
  font-style: normal;
}

.bold {
  font-family: var(--font-family-body-bold);
}

.button {
  background-color: var(--surface-secondary);
  color: var(--content-primary);
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .container {
    top: $logobar-height;
    max-width: $spacing * 70;
    margin-left: $spacing * 23;
  }

  .text {
    font-size: $spacing * 4;
  }
}
