@import "shared/styles/core";

$toggler-height: ($spacing * 18);

.topBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  min-height: $topbar-min-height;
  padding: {
    top: $spacing * 3;
    right: 0;
    bottom: $spacing * 3;
    left: $spacing * 4;
  }

  font-family: var(--font-family-navigation);
  font-style: var(--font-style-navigation);
  background: var(--surface-secondary);
  color: var(--content-primary);
  border-bottom: $default-border-width solid var(--content-primary);
}

.header {
  text-decoration: none;
  width: 100%;
}

.link {
  display: block;
  padding-top: $spacing;
  color: inherit;
  text-decoration: none;
}

.label {
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: $spacing * 3;
  font-size: var(--font-size-navigation-header);
  font-family: var(--font-family-navigation);
  font-weight: var(--font-weight-navigation);
  line-height: 1.34;
  color: inherit;
}

.labelText {
  height: auto;
  width: 100%;
  word-break: break-word;

  &.clamped {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.back {
  margin: 0;
  min-width: $small-icon-button-size;
  color: var(--content-primary);
  background: transparent;
  border: none;
  box-shadow: none;

  &:hover {
    color: var(--content-primary);
  }

  &:active,
  &.active,
  &:not(:disabled):active {
    border: none;
    box-shadow: none;
  }
}

.labelActive {
  color: var(--link-on-surface);
}

.iconColor {
  color: inherit;
  transition: none;
}

// @todo: Remove?
button.icon {
  @extend .iconColor;

  transform: rotate(-90deg);
}

.iconActive {
  @extend .iconColor;

  transform: rotate(90deg);
}

.action {
  display: flex;
  align-items: center;
  align-self: center;
  height: 100%;
}

.navDesktop,
.userButton {
  display: none;
  cursor: pointer;
}

.menuMobileWrapper {
  margin-right: ($spacing * 3);
}

.feedLabel {
  margin: 0 ($spacing * 3) 0 0;
  position: relative;
  opacity: 0.48;

  &:last-child {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    opacity: 1;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 8px solid var(--content-primary);
      position: absolute;
      bottom: var(--active-position);
      left: 50%;
      transform: translate(-50%, 0);
      transition: all 0.1s linear;
    }
  }
}

.feedLabel.sport {
  &.active {
    &::after {
      bottom: -14px;
    }
  }
}

.arrowIcon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in;
}

.feedToggler {
  position: relative;
  display: flex;
  align-items: center;

  &.active {
    .arrowIcon {
      transform: rotateX(180deg);
    }
  }
}

.largeFeedToggler {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 6px;
  padding: ($spacing * 2) ($spacing * 3);

  &:hover:not(&.active) {
    .navLink,
    .toggleArrow {
      color: var(--link-on-surface);
    }
  }

  &.active {
    background: var(--primary-p500);
    color: var(--color-button-text-primary);

    .toggleArrow {
      color: var(--color-button-text-primary);
    }
  }

  .toggleArrow {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: ($spacing * 2);
    transition: 0.2s background ease-in;
    cursor: pointer;
    color: var(--content-tertiary);
  }

  .feedToggleMenu {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -($toggler-height);
    left: 0;
    min-width: ($spacing * 31);
    height: $toggler-height;
    background: var(--primary-p500);
    color: var(--content-tertiary);
    font-size: var(--font-size-navigation-md);
    font-weight: bold;
    letter-spacing: 0.02em;
    pointer-events: none;
    opacity: 0;
    cursor: pointer;
    white-space: nowrap;
    z-index: 2;
    border-radius: 6px;
    border: 1px solid var(--gray-g100);
    box-shadow: 0 $spacing ($spacing * 3) rgb(0 0 0 / 8%);

    &.sport {
      text-transform: uppercase;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
      background: var(--surface-tertiary);
    }
  }

  .feedMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .feedMenuItem {
    display: flex;
    align-items: center;
    padding: ($spacing * 2);
    width: 100%;

    &:hover {
      color: var(--link-on-surface);
    }
  }

  .feedMenuLabel {
    margin-left: ($spacing * 2);
  }

  .navLink {
    transition: none;
  }
}

.burgerMenu {
  position: absolute;
  top: -$logobar-height;
  left: -100vw;
  height: calc(100vh - #{$logobar-height});
  width: 100vw;
  padding: 0 ($spacing * 4) ($spacing * 14);
  transform: scaleX(0);
  transform-origin: 0 0;
  transition:
    transform 0.5s $transition-cubic,
    opacity 0.4s ease-in;
  background: var(--primary-p500);
  opacity: 0;
  pointer-events: none;
  z-index: $layer-1;
  visibility: hidden;

  &.active {
    left: 0;
    opacity: 1;
    transform: scaleX(1);
    pointer-events: all;
    visibility: visible;
  }

  &.pwa {
    top: 0;
    height: 100vh;
  }
}

.closeWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: $logobar-height;

  .closeButton {
    color: var(--color-burger-links);
    margin: 0 ($spacing * 0.5) 0 0;

    & > svg {
      display: block;
      width: ($spacing * 3);
      height: ($spacing * 3);
    }
  }
}

.menuWrapper {
  margin: ($spacing * 2) 0 0 0;
  height: calc(100% - 80px);
  overflow-y: scroll;
}

.subMenu {
  list-style: none;
  margin: 0 0 ($spacing * 5) 0;
  padding: 0 0 0 ($spacing * 6);
  border-bottom: $default-border-width solid var(--core-black);

  &:last-child {
    border: 0;
  }
}

.subItem {
  margin: 0 0 ($spacing * 5) 0;
  line-height: 1.1;
  font-family: var(--font-family-body);
  font-weight: normal;
  font-size: var(--font-size-medium);
}

.subLink {
  text-decoration: none;
  color: var(--color-burger-links);

  &.special {
    font-family: var(--font-family-heading);
    font-weight: var(--font-weight-heading);
    font-size: var(--font-size-h3);
  }
}

@include media-breakpoint-up(lg) {
  .header {
    display: none;
  }

  .topBar {
    display: grid;
    align-items: center;
    padding: 0;
    background-color: var(--surface-secondary);
    grid-template-columns: minmax(min-content, 1fr) 110px 1fr;
    margin-top: 0;
    border-bottom: 1px solid var(--gray-g25);
  }

  .logo {
    justify-self: center;
    position: relative;

    .link {
      color: var(--logo);
    }
  }

  .burgerIcon {
    transition: none;
    margin-right: ($spacing * 4);
  }

  .action {
    width: auto;
    justify-content: flex-end;
    padding-right: ($spacing * 8);
  }

  .search {
    background: var(--gray-g50);
    color: var(--content-primary);
    border-radius: 50%;
    padding: ($spacing * 2);
    display: flex;
    margin-right: ($spacing * 4);
    transition: background 0.2s ease-in;

    &:hover {
      background: var(--gray-g200);
    }

    &:active {
      background: var(--gray-g50);
      color: var(--link-on-surface);
    }
  }

  .iconMenuDesktop {
    display: flex;
  }

  .menuMobileWrapper {
    margin-right: 0;
  }

  .navLink {
    color: var(--content-tertiary);
    display: flex;
    padding: ($spacing * 2) ($spacing * 3);
    font-size: var(--font-size-navigation-md);
    font-weight: var(--font-weight-navigation);
    height: 100%;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.2s ease-in;
    white-space: nowrap;
    border-radius: 6px;
    transition: color 0.2s ease-in;

    &:hover {
      color: var(--link-on-surface);
    }

    &.active {
      color: var(--navbar-color-active);
      background-color: var(--primary-p500);
    }

    .nameLabel {
      margin-left: ($spacing * 2);
      line-height: ($spacing * 4);

      &.net {
        text-transform: none;
      }
    }

    .music {
      height: ($spacing * 4);
      overflow: hidden;
      max-width: 5ch;
    }

    &.switch {
      padding: 0;
    }
  }

  .navDesktop,
  .userButton {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .navDesktop {
    padding-left: ($spacing * 8);

    .navWrapper {
      display: flex;
      align-items: center;
      background: var(--surface-primary);
      border: 1px solid var(--gray-g100);
      border-radius: ($spacing * 2);
      padding: ($spacing * 0.5);
    }
  }

  .menuMobile {
    display: none;
  }

  .iconColor {
    color: var(--core-white);
  }

  .burgerMenu {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    max-width: 420px;
    overflow: hidden;
  }

  .closeWrapper {
    margin: 0 0 ($spacing * 4);
    justify-content: flex-start;

    .closeButton {
      margin: $spacing 0 0 (-$spacing * 2);
    }
  }

  .menuWrapper {
    overflow-y: hidden;
  }

  .subItem {
    margin: 0 0 ($spacing * 6) 0;

    &.login {
      display: none;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navLink {
    .music {
      max-width: 100%;
    }
  }
}
