@import "shared/styles/core";

$large-button-height: 48px;
$button-height: 40px;
$small-button-height: 32px;
$player-desktop-button-height: 36px;

%ButtonBase {
  @include button-font;

  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}

.Button {
  @extend %ButtonBase;

  border: 0;
  box-shadow: none;
  border-radius: $default-border-radius;
  font-size: var(--font-size-button);
  line-height: 20px;
  height: $button-height;
  padding: 0 ($spacing * 5);
  cursor: pointer;
  outline: none;
  transition: color 0.2s ease-in, background 0.2s ease-in;

  &.sport {
    text-transform: uppercase;
  }

  &.small {
    height: $small-button-height;
  }

  &.large {
    height: $large-button-height;
  }
}

.primary {
  color: var(--color-button-text-primary);
  background: var(--primary-p500);

  &:hover {
    background-color: var(--primary-p600);
  }

  &:active {
    background-color: var(--primary-p700);
  }

  &:disabled {
    &,
    &:hover,
    &:active {
      color: var(--gray-g800);
      background-color: var(--gray-g100);
      cursor: default;
    }
  }
}

.primaryStroke {
  color: var(--link-on-surface);
  background: transparent;
  border: 1px solid var(--link-on-surface);

  &:hover {
    color: var(--link-on-surface-hover);
  }

  &:active {
    color: var(--link-on-surface-hover);
    border-color: var(--link-on-surface-hover);
    background: var(--primary-button-stroke-background-active);
  }

  &:disabled {
    &,
    &:hover,
    &:active {
      color: var(--gray-g800);
      border-color: var(--gray-g800);
      cursor: default;
    }
  }
}

.secondary {
  color: var(--content-primary);
  background-color: var(--surface-secondary);
  border: 1px solid var(--core-black);

  &:hover {
    color: var(--link-on-surface);
  }

  &:active {
    color: var(--link-on-surface-hover);
  }

  &:disabled {
    &,
    &:hover,
    &:active {
      color: var(--gray-g800);
      background-color: var(--gray-g100);
      border: 0;
      cursor: default;
    }
  }
}

.secondaryStroke {
  color: var(--content-primary);
  background: transparent;
  border: 1px solid var(--content-primary);

  &:hover {
    color: var(--link-on-surface);
  }

  &:active {
    color: var(--link-on-surface);
    background: var(--secondary-button-stroke-background-active);
  }

  &:disabled {
    &,
    &:hover,
    &:active {
      color: var(--gray-g800);
      border-color: var(--gray-g800);
      cursor: default;
    }
  }
}

.tertiary {
  background-color: transparent;
  color: var(--link-on-surface);

  &:hover {
    background: var(--secondary-button-stroke-background-active);
  }

  &:active {
    background: var(--tertiary-button-stroke-background-active);
  }

  &:disabled {
    &,
    &:hover,
    &:active {
      color: var(--gray-g800);
      background-color: transparent;
      cursor: default;
    }
  }
}

.playerPrimary {
  color: var(--color-button-text-primary);
  background: var(--primary-p500);
  border-radius: ($spacing * 1.5);
  gap: $spacing;

  &:hover {
    background-color: var(--primary-p600);
  }
}

.playerSecondary {
  color: var(--primary-p500);
  background-color: var(--core-white);
  border-radius: ($spacing * 1.5);
  gap: $spacing;

  &.sport {
    color: var(--foundation-on-primary);
  }

  &:hover {
    color: var(--primary-p600);
    box-shadow: 0 2px 12px rgb(0 0 0 / 8%);

    &.sport {
      color: var(--foundation-on-primary);
    }
  }
}

@include media-breakpoint-up(lg) {
  .playerPrimary,
  .playerSecondary {
    height: $player-desktop-button-height;
  }
}
