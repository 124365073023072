@import "shared/styles/core";

.appBar {
  position: fixed;
  top: -1px;
  right: 0;
  left: 0;
  width: 100%;
  font-family: var(--font-family-navigation);
  font-style: var(--font-style-navigation);
  z-index: $top-layer;
  transition: transform 0.2s ease-in;
  backface-visibility: hidden;

  &.pwa {
    .wrapper {
      display: none;
    }
  }

  &.scrolling {
    transform: translate3d(0, -#{$logobar-height}, 0);

    &.pwa {
      transform: translate3d(0, -1px, 0);
    }
  }
}

.logoBar {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: $logobar-height;
  padding: ($spacing * 3);
  background-color: var(--logo-bar-background);
  color: var(--logo-bar);
  position: relative;

  &.pwa {
    display: none;
  }
}

.logo {
  transition: all 0.4s ease-in;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.samsungLogo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .appBar {
    top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    display: none;
  }

  .appBar {
    &.scrolling {
      transform: translate3d(0, -60px, 0);
    }
  }
}

@media all and (display-mode: standalone) {
  .appBar {
    .wrapper {
      display: none;
    }
  }

  .logoBar {
    display: none;
  }
}
