@import "shared/styles/core";

$visible-menu-height: 32px;
$scroll-height: 8px;
$top-player-width: 496px;

.wrapper {
  width: 100%;
  //height: $visible-menu-height + $scroll-height;
  overflow: hidden;
  transition: opacity 0.2s ease-in;
  background: transparent;
  border-bottom: 1px solid var(--gray-g50);

  &.mobileHidden {
    display: none;
  }

  &.scrolling {
    opacity: 0;
  }
}

.navBar {
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  //height: $visible-menu-height + ($scroll-height * 2);
  overflow: scroll;
  background-color: var(--surface-secondary);
  padding: ($spacing * 2) 0;
  font-family: var(--font-family-navigation);
  font-style: var(--font-style-navigation);
  color: var(--content-primary);
  box-shadow: var(--topbar-box-shadow);

  &:empty {
    display: none;
  }
}

.link {
  margin-right: $spacing * 2;
  padding: 0 ($spacing * 3);
  font-size: var(--font-size-navigation);
  font-weight: var(--font-weight-navigation);
  letter-spacing: -0.01em;
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid var(--gray-g100);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ($spacing * 7);
  line-height: 1;

  &:first-child {
    margin-left: ($spacing * 4);
  }

  &.sport {
    text-transform: uppercase;
  }

  &.active {
    color: var(--content-secondary);
    background-color: var(--content-primary);
    border-color: var(--content-primary);
  }
  &.activeSBM {
    color: var(--core-black);
    background-color: #ffc400;
    border-color: var(--content-primary);
  }
}

.player,
.navButton,
.button {
  display: none;
}

.slider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: $navbar-height;
    background: var(--surface-secondary);
    overflow: visible;
    margin-top: 0;
    border-bottom: 0;

    &.mobileHidden {
      display: flex;
    }

    &.scrolling {
      opacity: 1;
    }
  }

  .navBar {
    flex: 1 1 100%;
    align-items: center;
    height: 100%;
    background-color: var(--surface-secondary);
    padding: {
      top: 0;
      right: ($spacing * 4);
      left: ($spacing * 12);
      bottom: 0;
    }
  }

  .link {
    display: flex;
    align-items: center;
    margin-right: ($spacing * 2);
    padding: $spacing $spacing * 3;
    line-height: 20px;
    color: var(--content-primary);
    font-weight: var(--font-weight-navigation);
    font-size: var(--font-size-navigation-md);
    border: 1px solid var(--gray-g100);
    transition: border-color 0.2s ease-in;

    &:hover {
      border-color: var(--content-primary);
    }

    &.active {
      color: var(--content-secondary);
      background-color: var(--content-primary);
      border-color: transparent;
    }
  }

  .player {
    display: block;
    flex: 0 0 $top-player-width;
    max-width: $top-player-width;
    background: var(--primary-p500);
  }

  .slider {
    margin: 0 (-$spacing * 12) 0 ($spacing * 2);

    .link:first-child {
      margin-left: 0;
    }
  }

  .button {
    position: absolute;
    background: transparent;
    border: none;
    width: ($spacing * 20);
    display: flex;
    visibility: hidden;
    background: var(--navbar-shadow-left);
    height: 100%;
    align-items: center;
  }

  .navButton {
    background: transparent;
    border: none;
    display: flex;
    width: ($spacing * 20);
    z-index: 1;
    visibility: hidden;
    height: 100%;
    align-items: center;
  }

  .nextButton {
    justify-content: flex-end;
    width: ($spacing * 25);
    background: var(--navbar-shadow-right);
  }

  .arrow {
    color: var(--content-primary);
    cursor: pointer;
    min-width: ($spacing * 3.5);
  }

  .nextArrow {
    transform: rotate(180deg);
  }

  .visible {
    visibility: visible;
  }
}
