@import "shared/styles/core";

.logo {
  transition: all 0.4s ease-in;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.samsungLogo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.net {
  height: 19px;
  width: 105px;
}

@include media-breakpoint-up(lg) {
  .samsungLogo {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}
