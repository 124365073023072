@import "shared/styles/core";

.link {
  color: var(--content-primary);
  display: flex;
  align-items: center;
}

.name {
  margin-left: ($spacing * 3);
}

.arrow {
  color: var(--content-primary);
  display: none;
  transition: transform 0.15s ease-in;

  &.user {
    display: inline-flex;
  }

  &.active {
    transform: rotate(180deg);
  }
}

.dropdown {
  position: absolute;
  right: ($spacing * 0.5);
  top: $topbar-min-height;
  display: none;
  padding: ($spacing * 2);
  z-index: $layer-9;
  background: var(--surface-tertiary);
  border: 1px solid var(--gray-g100);
  box-shadow: 0 2px 16px rgb(0 0 0 / 8%);
  border-radius: 10px;
  backface-visibility: hidden;
  min-width: ($spacing * 60);

  &.active {
    display: block;
  }

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .item {
    color: var(--content-primary);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: var(--font-size-dropdown);
    font-family: var(--font-family-dropdown);
    font-weight: var(--font-weight-dropdown);

    &:hover {
      background: var(--gray-g50);
      border-radius: 6px;
    }

    &:nth-last-child(2) {
      position: relative;
      margin-top: ($spacing * 4);
      margin-bottom: ($spacing * 4);
      padding-right: ($spacing * 2);
    }

    &:nth-last-child(2)::before,
    &:nth-last-child(2)::after {
      content: "";
      height: 1px;
      width: 100%;
      background-color: var(--gray-g300);
      flex-basis: 100%;
      position: absolute;
    }

    &:nth-last-child(2)::before {
      top: -($spacing * 2);
    }

    &:nth-last-child(2)::after {
      bottom: -($spacing * 2);
    }
  }

  .label {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: ($spacing * 2.5) ($spacing * 2);
  }

  .link {
    color: var(--content-primary);
    text-decoration: none;
    padding: ($spacing * 2.5) ($spacing * 2);
    width: 100%;

    &:hover {
      color: var(--content-primary);
    }
  }
}
